export function filterDogs (dogs = [], criteria = []) {
  const filter = {}
  // Converts the criteria array to an object with their positions
  criteria.forEach((c, i) => { filter[c] = i + 1 })

  const pinnedDogs = []
  const filteredDogs = []
  // Divides the instances specified by the criteria
  dogs.forEach(dog => {
    if (filter[dog.id]) {
      pinnedDogs.push({
        dog,
        position: filter[dog.id]
      })
    } else {
      filteredDogs.push(dog)
    }
  })

  // Returns two arrangements. 'pinnedDogs' contains those specified by the criteria and ordered by their position
  return {
    pinnedDogs: pinnedDogs.sort((a, b) => a.position - b.position).map(d => d.dog),
    filteredDogs
  }
}

export function pickDogs (dogs, landingPhotos) {
  const result = []

  // Parses the stringified criteria array
  const criteria = landingPhotos ? JSON.parse(landingPhotos) : []
  const { pinnedDogs, filteredDogs } = filterDogs(dogs, criteria)

  // If 'pinnedDogs' length is odd completes the array with a random puppy from 'filteredDogs'
  if (pinnedDogs.length % 2) {
    const x = Math.floor(Math.random() * filteredDogs.length)
    const dog = filteredDogs.splice(x, 1)

    pinnedDogs.push(...dog)
  }
  // Makes the pairs and pushes them in 'result' array
  while (pinnedDogs.length) {
    const dog = pinnedDogs.splice(0, 1)
    const dog2 = pinnedDogs.splice(0, 1)

    result.push([...dog, ...dog2])
  }

  // Organizes 'filteredDogs' by price ranges
  const puppyArr = [
    filteredDogs.filter(puppy => puppy.salePrice?.subTotal <= 125000),
    filteredDogs.filter(puppy => puppy.salePrice?.subTotal > 125000 && puppy.salePrice?.subTotal <= 150000),
    filteredDogs.filter(puppy => puppy.salePrice?.subTotal > 150000 && puppy.salePrice?.subTotal <= 180000),
    filteredDogs.filter(puppy => puppy.salePrice?.subTotal > 180000 && puppy.salePrice?.subTotal <= 250000),
    filteredDogs.filter(puppy => puppy.salePrice?.subTotal > 250000)
  ]

  let n = filteredDogs.length > 60 ? 60 : filteredDogs.length
  const len = n

  // Completes 'result' array with pairs from different price ranges, low to high.
  while (n--) {
    const midArr = (len - n + -1) % puppyArr.length

    if (puppyArr[midArr].length > 1) {
      const x = Math.floor(Math.random() * puppyArr[midArr].length)
      const dog = puppyArr[midArr].splice(x, 1)[0]

      const y = Math.floor(Math.random() * puppyArr[midArr].length)
      const dog2 = puppyArr[midArr].splice(y, 1)[0]

      result.push([dog, dog2])
    }
  }

  return result
}

export function getBreedImage (breed) {
  if (breed?.profilePicture?.small) return breed?.profilePicture?.small

  for (let i = 0; i < breed?.dogs?.length; i++) {
    if (breed?.dogs[i].isAvailable && breed?.dogs[i].profilePicture?.small) {
      return breed.dogs[i].profilePicture.small
    }
  }
  return ''
}

export function filterBreeds (breeds = []) {
  return breeds
    .map(breed => ({ ...breed, dogs: breed.dogs.filter(d => !!d.profilePicture?.small && d.isAvailable) }))
    // .filter(breed => breed.dogs.length)
    .sort((a, b) => (a.dogs.length > b.dogs.length ? -1 : 1))
}

export function pickBreeds (_breeds = []) {
  const breeds = [..._breeds]

  const pickedBreeds = []
  while (breeds.length > 1) {
    const breed = breeds.splice(0, 1)[0]
    const breed2 = breeds.splice(0, 1)[0]

    pickedBreeds.push([breed, breed2])
  }

  return pickedBreeds
}

export function filterByCategories (dogs = [], categoryValue = []) {
  return dogs.filter((d) => d.breed[categoryValue] === true && d.images.length > 0)
}

export function filterDogsByQuery (dogs = [], criteria = [], query) {
  const { pinnedDogs, filteredDogs: _filteredDogs } = filterDogs(dogs, criteria)
  const filteredDogs = _filteredDogs.filter(d => d?.name?.toLowerCase().indexOf(query.toLowerCase().trim()) > -1) || []
  return {
    pinnedDogs,
    filteredDogs
  }
}

export const SETTING_BY = {
  HOME: 'Home',
  SEARCH: 'Search Without Filter',
  CATEGORY: 'Category',
  BREED: 'Breed'
}

export function capitalizeFirstLetter (string) {
  return `${string.charAt(0).toUpperCase()} ${string.slice(1)}`
}

export const altTextMap = {
  family: 'Australian shepherd lying down in a home backyard',
  apartment: 'White dog sitting in a bed in an apartment',
  companion: 'Beagle dog sitting in the lap of older woman reading a book',
  mini: 'Small furry beige dog running',
  hypoallergenic: 'Poodle mix dog with tongue out',
  doodles: 'Poodle mix dog with tongue out',
  service: 'White service dog sitting in front of man in a wheelchair',
  active: 'Huskie and Border Collie dogs running in snow',
  guard: 'Grey American Bulldog sitting with tongue out',
  affordable: 'Lady smiling and hugging her brown dog'
}
