import { ReactNode, JSX } from 'react'
import cn from 'classnames'
import styles from './Title.module.scss'

export type TitleProps = {
  id?: string
  children: ReactNode | string
  variant: 'h1' | 'h2' | 'h3'
  className?: Record<string, string>
}

const Title = ({
  id,
  children,
  variant,
  className,
}: TitleProps): JSX.Element => {
  const _variant = {
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
  }[variant]

  const getProps = () => {
    return {
      ...(id && { id: `title-${id}` }),
      className: cn(_variant, styles.title, className),
      children,
    }
  }

  const variants = {
    h1: <h1 {...getProps()} />,
    h2: <h2 {...getProps()} />,
    h3: <h3 {...getProps()} />,
  }

  return variants[variant]
}

export default Title
