/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { useQuery } from '@apollo/client'
// Components
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './QueryOptions.module.scss'
import Button from '../newButton/Button'
// Utils
import { convertCategory } from './lib/utils'
import Loader from '../newLoader/Loader'
import { GET_DOGS_BY_NAME } from '@/graphql/index'

export default function QueryOptions({
  selectedBreed,
  selectedCategory,
  categories,
  filteredBreeds,
  setShowOptions,
  showOptions,
  setNewRoute,
  setShowNotificationForm,
  // Only needed for mobile
  isMobile,
  query,
  setQuery,
  loading,
  hasBanner,
}) {
  const router = useRouter()
  const [onView, setOnView] = useState('search')
  const [notifyBubble, setNotifyBubble] = useState({
    show: false,
    slug: '',
  })

  const {
    loading: filteredDogsLoading,
    data: filteredDogs,
    refetch,
  } = useQuery(GET_DOGS_BY_NAME, {
    variables: { name: query },
  })

  useEffect(() => {
    refetch()
  }, [query])

  function Search() {
    if (loading || filteredDogsLoading) {
      return (
        <div className={styles.noMatching}>
          <Loader theme="short" />
        </div>
      )
    }
    if (!filteredBreeds.length && !filteredDogs?.dogsByName?.length) {
      return (
        <div className={styles.noMatching}>
          <img src="/search/error.svg" alt="error" />

          <p>No matching breeds or puppies found.</p>
        </div>
      )
    }
    return (
      <>
        {!!filteredBreeds.length && (
          <div className={styles.title}>
            <div>Breeds</div>
          </div>
        )}
        <div
          className={cn({
            [styles.dataContent]: true,
            [styles.notDogs]: filteredDogs?.dogsByName?.length === 0,
          })}
        >
          {filteredBreeds?.map((breed, i) => (
            <div className={styles.option} key={i}>
              <div
                className={styles.imgWrapper}
                onClick={() => {
                  setShowOptions(false)
                  setNewRoute('breed', breed.slug, 'category', null)
                }}
              >
                {breed.profilePicture ? (
                  <img
                    className={cn(
                      styles.profilePicture,
                      !breed.count ? styles.unavailable : null,
                    )}
                    src={
                      process.env.NEXT_PUBLIC_MEDIA_BUCKET +
                      breed.profilePicture
                    }
                    alt={breed.name}
                  />
                ) : (
                  <img
                    className={styles.picturePlaceholder}
                    src="/search/dog-placeholder.svg"
                    alt="dog placeholder"
                  />
                )}
              </div>

              <div
                className={styles.info}
                onClick={() => {
                  setShowOptions(false)
                  setNewRoute('breed', breed.slug, 'category', null)
                }}
              >
                <h3
                  className={
                    breed.count === 0 || !breed.hasDogs
                      ? styles.unavailable
                      : null
                  }
                >
                  {breed.name}
                </h3>

                <p
                  className={
                    breed.count === 0 || !breed.hasDogs
                      ? styles.unavailable
                      : null
                  }
                >
                  {breed.count === 0 || !breed.hasDogs
                    ? 'Temporarily Unavailable'
                    : breed.count === 1 && breed.hasDogs
                      ? '1 puppy'
                      : `${breed.count} puppies`}
                </p>
              </div>

              {breed.count === 0 || !breed.hasDogs ? (
                <img
                  className={styles.unavailableIcon}
                  src="/search/bell-pink.svg"
                  onMouseEnter={() =>
                    setNotifyBubble({ show: true, slug: breed.slug })
                  }
                  alt="Notify me when available"
                />
              ) : (
                <img
                  className={styles.availableIcon}
                  src="/search/arrow-right-green.svg"
                  alt="View puppies"
                />
              )}

              {notifyBubble.show &&
                !breed.count &&
                notifyBubble.slug === breed.slug && (
                  <div className={styles.notificationBubble}>
                    <p>
                      Get notified when {breed.name} puppies become available!
                    </p>
                    <div
                      className={styles.bubbleButton}
                      onClick={() => setShowNotificationForm(true)}
                    >
                      Notify Me
                    </div>
                    <div
                      className={styles._arrow}
                      style={{
                        backgroundColor: '#C6F6E3',
                        borderTop: 'unset',
                        borderLeft: 'unset',
                      }}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>

        {!!filteredDogs?.dogsByName?.length && (
          <div className={styles.title}>Puppies</div>
        )}
        <div className={styles.dataContent}>
          {filteredDogs?.dogsByName?.map((dog, i) => (
            <div
              className={styles.option}
              key={i}
              onClick={() => {
                setShowOptions(false)
                router.push(`/puppy/${dog.id}`)
              }}
            >
              <div className={styles.imgWrapper}>
                <img
                  className={styles.profilePicture}
                  src={
                    process.env.NEXT_PUBLIC_MEDIA_BUCKET +
                    dog.profilePicture?.small
                  }
                  alt={dog.name}
                />
              </div>

              <div className={styles.info}>
                <h3>{dog.name}</h3>

                <p>{dog.breed?.name}</p>
              </div>

              <img
                className={styles.availableIcon}
                src="/search/arrow-right-green.svg"
                alt="View puppy"
              />
            </div>
          ))}
        </div>
      </>
    )
  }

  function Categories() {
    return (
      <>
        <div className={styles.title}>
          <div>Dog Categories</div>
        </div>

        {Object.keys(categories).map((c, i) => {
          if (c === '__typename') return null

          return (
            <div
              className={cn(styles.option, styles.category)}
              key={i}
              onClick={() => {
                setShowOptions(false)
                setNewRoute('category', c, 'breed', null)
              }}
            >
              <div className={styles.iconContainer}>
                <img src={`/search/${c}.svg`} alt="category" />
              </div>

              <div className={styles.info}>
                <h3>{convertCategory(c)}</h3>

                <p>
                  {categories[c] === 1
                    ? '1 puppy'
                    : `${categories[c] || 0} puppies`}
                </p>
              </div>

              <img
                className={styles.availableIcon}
                src="/search/arrow-right-green.svg"
                alt="view puppies"
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className={styles.options}>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (selectedBreed?.name) setQuery(selectedBreed?.name)
          if (selectedCategory) setQuery(convertCategory(selectedCategory))
          if (showOptions) setShowOptions(false)
        }}
      >
        <div className={styles.arrow} />

        <div className={hasBanner || false ? styles.highHeader : styles.header}>
          {isMobile && (
            <div
              className={styles.backIcon}
              onClick={() => {
                if (selectedBreed?.name) {
                  setQuery(selectedBreed?.name)
                }
                if (selectedCategory) {
                  setQuery(convertCategory(selectedCategory))
                }
                setShowOptions(false)
                window.scrollTo(0, 0)
              }}
            >
              <img src="/search/back.svg" alt="back arrow" />
            </div>
          )}

          <div
            style={{ marginRight: isMobile ? '25px' : '50px' }}
            className={styles.section}
            onClick={() => {
              if (onView !== 'search') setOnView('search')
            }}
          >
            <h4>Search</h4>

            <div
              className={
                onView === 'search' ? styles.selected : styles.notSelected
              }
            />
          </div>

          <div
            className={styles.section}
            onClick={() => {
              if (onView !== 'categories') setOnView('categories')
            }}
          >
            <h4>Browse Categories</h4>

            <div
              className={
                onView === 'categories' ? styles.selected : styles.notSelected
              }
            />
          </div>
        </div>

        {isMobile && onView === 'search' && (
          <form className={styles.browse}>
            <input
              type="text"
              placeholder="Enter breed or puppy name"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {/* CLEAR INPUT */}
            {query && query.length > 0 && (
              <div className={styles.clear} onClick={() => setQuery('')}>
                <img src="/close-modal-grey.svg" alt="close icon" />
              </div>
            )}
          </form>
        )}

        <div
          className={cn(
            styles.content,
            onView === 'search' ? styles.search : styles.categories,
          )}
        >
          {onView === 'search' && <Search />}
          {onView === 'categories' && <Categories />}

          {isMobile && (
            <div className={styles.banner}>
              <div className={styles.bannerContent}>
                <h3>Find your perfect puppy!</h3>

                <h5>Take our interactive Puppy Quiz!</h5>

                <Button
                  title="Puppy Match Quiz"
                  theme={['short', 'yellow-solid']}
                  onClick={() => {
                    router.push('/dog-breed-quiz')
                  }}
                />
              </div>

              <div className={styles.imageContainer}>
                <img src="/search/wizard.png" alt="Let our Wizard help you!" />
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}
