import { filterDogs } from '../../home/lib/utils'

export const getPageTitle = (category, breedName) => {
  let pageTitle
  let dogCategory

  if (category) {
    switch (category) {
      case 'kid_friendly':
        dogCategory = 'Kid-Friendly Dogs'
        break

      case 'small':
        dogCategory = 'Small'
        break

      case 'emotional_support':
        dogCategory = 'Emotional Support'
        break

      case 'guard':
        dogCategory = 'Guard Dog'
        break

      case 'hunting':
        dogCategory = 'Hunting Dog'
        break

      default:
        dogCategory = category[0].toUpperCase() + category.slice(1)
        break
    }

    pageTitle = `${dogCategory} for Sale by trusted and loving breeders | Mawoopets.com`
  } else if (breedName) {
    // Sets different page title for breed page depending on first letter of the breed name
    // Breeds A-H - [Breed] Puppies for Sale | Mawoopets.com
    const range1 = /[a-h]/i
    const isMatch = breedName.startsWith(breedName.match(range1))
    // Breeds I-P - [Breed] Puppies for Sale | Find Adorable Puppies | Mawoopets.com
    const range2 = /[i-p]/i
    const isMatch2 = breedName.startsWith(breedName.match(range2))

    if (isMatch) {
      pageTitle = `Browse ${breedName} Puppies | MawooPets.com`
    } else if (isMatch2) {
      pageTitle = `Browse ${breedName} Puppies | MawooPets.com`
    } else {
      // Breeds Q-Z - Find [Breed] Puppies for Sale - Trusted Breeders | Mawoopets.com
      pageTitle = `Find ${breedName} Puppies | MawooPets.com`
    }
  } else {
    pageTitle = 'Browse Puppies from 1,000+ Breeders | MawooPets.com'
  }

  return { pageTitle, dogCategory }
}

export const getPageDescription = (category, breedName) =>
  breedName
    ? `Browse adorable ${breedName} puppies for sale from our network of 1,000+ breeders. Live Customer Support. Nationwide Delivery. Start your search today!`
    : category
      ? `Browse our ${category} dogs for sale by the most trusted source. Buy a ${category} dog by a Mawoo Pets trusted & vetted breeder near you.`
      : 'Browse adorable puppies for sale from our network of 1,000+ breeders. Live Customer Support. Nationwide Delivery. Start your search today!'

export const getPath = ({
  breed,
  category,
  zipcode,
  stateName,
  price,
  size,
  gender,
  hypoallergenic,
  color,
  sort,
  page
}) => {
  const pathArr = []
  if (breed) pathArr.push(`breed=${breed}`)
  if (category) pathArr.push(`category=${category}`)
  if (stateName) pathArr.push(`state=${stateName}`)
  if (zipcode) pathArr.push(`zipcode=${zipcode}`)
  if (price) pathArr.push(`price=${price}`)
  if (size) pathArr.push(`size=${size}`)
  if (gender) pathArr.push(`gender=${gender}`)
  if (hypoallergenic) pathArr.push(`hypoallergenic=${hypoallergenic}`)
  if (color) pathArr.push(`color=${color}`)
  if (page) pathArr.push(`page=${page}`)
  if (sort) pathArr.push(`sort=${sort}`)
  const path = `/search${pathArr.length > 0 ? '?' : ''}${pathArr.join('&')}`
  return path
}

export const getDogs = (breeds = []) =>
  breeds
    .reduce((dogs, b) => [...dogs, ...b.dogs], [])
    .filter(d => d.profilePicture?.small && d.images?.length)

export const parseSortBy = str => {
  switch (str) {
    case 'by-view-score':
      return 'Featured'
    case 'most-viewed':
      return 'Most Viewed'
    case 'price-low-to-high':
      return 'Price: Low to High'
    case 'price-high-to-low':
      return 'Price: High to Low'
    case 'closest':
      return 'Distance'
  }
}

export const sortDogs = (dogs = [], sortBy, featureList) => {
  switch (sortBy) {
    case 'by-featured': {
      const criteria = featureList !== undefined ? JSON.parse(featureList) : []
      const { pinnedDogs, filteredDogs } = filterDogs(dogs, criteria)
      return [...pinnedDogs, ...filteredDogs]
    }
    case 'most-viewed':
      return [...dogs].sort((a, b) => b.views - a.views)
    case 'price-low-to-high':
      return [...dogs].sort(
        (a, b) => a.salePrice?.subTotal - b.salePrice?.subTotal
      )
    case 'price-high-to-low':
      return [...dogs].sort(
        (a, b) => b.salePrice?.subTotal - a.salePrice?.subTotal
      )
    case 'closest':
      return [...dogs].sort((a, b) => {
        if (!a.distance && a.distance !== 0) {
          return 1
        } else if (!b.distance && b.distance !== 0) {
          return -1
        }
        return a.distance - b.distance
      })
  }
  return dogs
}

export const stringifyKey = variables => {
  const _key = {}
  Object.keys(variables).forEach(k => {
    _key[k] = variables[k]
  })
  return JSON.stringify(_key)
}

export const convertCategory = category => {
  switch (category) {
    case 'kid_friendly':
      return 'Kid-Friendly Puppies'
    case 'small':
      return 'Small Puppies'
    case 'emotional_support':
      return 'Emotional Support Puppies'
    case 'hypoallergenic':
      return 'Hypoallergenic Puppies'
    case 'guard':
      return 'Guard Breeds'
    case 'service':
      return 'Service Puppies'
    case 'active':
      return 'Active Lifestyle Puppies'
    case 'doodle':
      return 'Doodle Puppies'
    case 'apartment':
      return 'Apartment Puppies'
    case 'affordable':
      return 'Puppies Under $1,000'
    case 'therapy':
      return 'Therapy Breeds'
    case 'trained':
      return 'Trained Breeds'
  }
  return null
}

export const PRICES = {
  1: { label: '$825 - $1000' },
  2: { label: '$825 - $1200' },
  3: { label: '$825 - $1400' },
  4: { label: '$825 - $1600 ' },
  5: { label: '$825 - $1800' },
  6: { label: '$825 - $2000' },
  7: { label: '$825 - $2200' },
  8: { label: '$825 - $2400' },
  9: { label: '$825 - $2600' },
  10: { label: '$825 - $2800' },
  11: { label: '$825 - $3000+' }
}
export const convertPrice = value => {
  if (value === null) return 'All'

  return PRICES[value]?.label || null
}

export const GENDERS = {
  Male: { label: 'Boy' },
  Female: { label: 'Girl' }
}
export const convertGender = value => GENDERS[value]?.label || null

export const SIZES = {
  1: { label: 'Toy (Up to 12lb)' },
  2: { label: 'Small (12-25lb)' },
  3: { label: 'Medium (25-50lb)' },
  4: { label: 'Large (50-100lb)' },
  5: { label: 'Giant (Over 100lb)' }
}
export const convertSize = value =>
  value.split(',').reduce((acc, value) => {
    const label = SIZES[value]?.label
    if (!label) return acc

    return acc ? `${acc}, ${label}` : label
  }, '')

export const HYPOALLERGENICS = {
  true: { label: 'Hypoallergenic' }
}
export const convertHypoallergenic = value =>
  HYPOALLERGENICS[value]?.label || null

export const COLORS = {
  black: {
    label: 'Black',
    icon: '/search/icon-colors/black.png'
  },
  brown: {
    label: 'Brown',
    icon: '/search/icon-colors/brown.png'
  },
  blue: {
    label: 'Blue',
    icon: '/search/icon-colors/blue.png'
  },
  cream: {
    label: 'Cream',
    icon: '/search/icon-colors/cream.png'
  },
  gold: {
    label: 'Gold',
    icon: '/search/icon-colors/gold.png'
  },
  grey: {
    label: 'Grey',
    icon: '/search/icon-colors/grey.png'
  },
  red: {
    label: 'Red',
    icon: '/search/icon-colors/red.png'
  }
}
export const convertColor = value =>
  value.split(',').reduce((acc, value) => {
    const label = COLORS[value]?.label
    if (!label) return acc

    return acc ? `${acc}, ${label}` : label
  }, '')

const ALL_OPTION = { value: null, label: 'All' }
export const mapOptions = ({ optionsDict, allowAll }) => {
  const options = Object.keys(optionsDict).map(value => {
    const { label, icon } = optionsDict[value]
    return {
      value,
      label,
      ...(icon && { icon })
    }
  })

  return allowAll ? [ALL_OPTION, ...options] : options
}

export const isFavourite = (favourites, id) => {
  return !!favourites?.filter(f => f.id === id).length
}

export const countWords = (str = '') => str.match(/(\w+)/g).length

export const truncate = (str = '', cut) => {
  const wordCount = countWords(str)
  if (wordCount >= cut) return str.split(' ').splice(0, cut).join(' ') + '...'
  return str
}
