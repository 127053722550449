import { initializeApollo } from './apolloClient'

const query = async ({ query, variables }) => {
  const apolloClient = initializeApollo()

  const { data } = await apolloClient.query({ query, variables })

  return data
}
export default query
